import React, {
  Dispatch, useContext, useEffect, useRef, useState, SetStateAction,
} from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import * as ls from 'local-storage'
import { Button } from '@mui/material'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { MachineFiltersHolder } from './MachineFilters.styles'
import { MachineData } from '../Machine/Machine.types'
import { API } from '../../providers/API'
import { theme } from '../../theme/theme'

interface MachineFiltersProps {
  setMachinesFunction: Dispatch<SetStateAction<never[]>>,
  filteredMachines: never[]
  setSelectedBranchFn?: Dispatch<SetStateAction<number | null>>,
  service?: boolean
  selectedBranch: number | null
}

interface BankSelectProps {
  label: string
  id: number
}

interface BranchSelectProps {
  label: string
  bankName: string
  branchId: number
}

export const MachineFilters: React.FC<MachineFiltersProps> = (
  {
    setMachinesFunction, filteredMachines, setSelectedBranchFn, selectedBranch, service = false,
  },
) => {
  const [branchListState, setBranchListState] = useState([])
  const [branchListStatePerm, setBranchListStatePerm] = useState([])
  const [bankListState, setBankListState] = useState([])
  const [selectedBankId, setSelectedBankId] = useState<number | null>(null)
  const [tempMachinesWithoutSN, setTempMachinesWithoutSN] = useState<never[]>()
  const [filteredMachinesSerialNumbers, setFilteredMachinesSerialNumbers] = useState<string[]>()
  const { setIsLoading, userAccess } = useContext(GlobalStateContext)

  const getMachinesInBank = (value: any) => {
    const machinesAPIURL = value !== null ? `/storage/listAllBankMachines/${value}` : '/machines'
    setIsLoading(true)
    API.get(machinesAPIURL, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setIsLoading(false)
      if (service) {
        const machinesInService = response.data.filter((item:any) => item.status === 'за ремонт')
        setMachinesFunction(machinesInService)
        setTempMachinesWithoutSN(machinesInService)
      } else {
        setMachinesFunction(response.data)
        setTempMachinesWithoutSN(response.data)
      }
    })
  }

  const getMachinesInBranch = (value: any) => {
    const machinesAPIURL = value !== null ? `/storage/listBankBranchMachines/${value}` : `/storage/listAllBankMachines/${selectedBankId}`
    setIsLoading(true)
    API.get(machinesAPIURL, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setIsLoading(false)
      if (service) {
        const machinesInService = response.data.filter((item:any) => item.status === 'за ремонт')
        setMachinesFunction(machinesInService)
        setTempMachinesWithoutSN(machinesInService)
      } else {
        setMachinesFunction(response.data)
        setTempMachinesWithoutSN(response.data)
      }
    })
  }

  const updateBranchList = (value: any) => {
    const newArray = branchListStatePerm.filter((item:any) => item.bankId === value)
    setBranchListState(newArray.length > 0 ? newArray : branchListStatePerm)
  }

  const filterBySN = (fieldValue: string | null) => {
    const filteredBySN = tempMachinesWithoutSN!.filter(
      (item: any) => item.serialNumber === fieldValue?.toString(),
    )
    if (filteredBySN.length > 0) {
      setMachinesFunction(filteredBySN)
    } else {
      setMachinesFunction(tempMachinesWithoutSN!)
    }
  }

  const refreshContent = () => {
    if (selectedBranch) {
      getMachinesInBranch(selectedBranch)
    } else {
      getMachinesInBank(selectedBankId)
    }
  }

  useEffect(() => {
    if (userAccess <= 2) {
      API.get('/bank', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
        const banksList:any = []
        response.data.forEach((item:any) => {
          banksList.push({ label: item.bankName, id: item.bankId })
        })

        setBankListState(banksList)
      })
    }

    if (userAccess <= 3) {
      API.get('/branch', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
        const branchList:any = []
        response.data.forEach((item:any) => {
          branchList.push({
            label: item.branchName, bankName: item.bankName, bankId: item.bankId, branchId: item.id,
          })
        })

        setBranchListStatePerm(branchList)

        if (userAccess <= 2) {
          setBranchListState(branchList)
        }
      })
    }
  }, [])

  const firstUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if (userAccess >= 3) {
      // replace number with dynamic value for userInfo.bankId
      updateBranchList(1)
    }
  }, [branchListStatePerm])

  const firstUpdateMachines = useRef(true)

  useEffect(() => {
    if (firstUpdateMachines.current && filteredMachines.length > 0) {
      firstUpdateMachines.current = false
      setTempMachinesWithoutSN(filteredMachines)
    }
    if (filteredMachines.length > 0) {
      const serialNumbers = filteredMachines.map((item: MachineData) => item.serialNumber)
      setFilteredMachinesSerialNumbers(serialNumbers)
    }
  }, [filteredMachines])

  return (
    <MachineFiltersHolder>
      {userAccess <= 2 && (
        <Autocomplete
          disablePortal
          options={bankListState}
          renderInput={(params) => <TextField {...params} label="Банка" />}
          fullWidth
          onChange={(event, newValue: BankSelectProps | null) => {
            updateBranchList(newValue?.id)
            getMachinesInBank(newValue ? newValue?.id : null)
            setSelectedBankId(newValue ? newValue?.id : null)
          }}
        />
      )}
      {userAccess <= 3 && (
        <Autocomplete
          disablePortal
          options={branchListState}
          renderInput={(params) => <TextField {...params} label="Клон" />}
          fullWidth
          onChange={(event, newValue: BranchSelectProps | null) => {
            if (setSelectedBranchFn) {
              setSelectedBranchFn(newValue ? newValue.branchId : null)
            }
            getMachinesInBranch(newValue ? newValue?.branchId : null)
          }}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Autocomplete
          disablePortal
          options={filteredMachinesSerialNumbers!}
          renderInput={(params) => <TextField {...params} label="Сериен номер" />}
          fullWidth
          onChange={(event, newValue: string | null) => {
            if (newValue?.length === 0 && tempMachinesWithoutSN) {
              setMachinesFunction(tempMachinesWithoutSN)
            } else {
              filterBySN(newValue)
            }
          }}
          style={{ marginRight: theme.space.md }}
        />
        <div>
          <Button
            variant="contained"
            type="button"
            color="primary"
            fullWidth
            onClick={refreshContent}
            style={{ height: '56px' }}
          >
            <span className="material-icons-outlined">
              cached
            </span>
          </Button>
        </div>
      </div>
    </MachineFiltersHolder>
  )
}
