import React, {
  useCallback, useContext, useEffect, useState, useRef,
} from 'react'
import * as ls from 'local-storage'
import { useHistory } from 'react-router-dom'
import { debounce } from 'lodash'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { MachineDataList, MachineHolder, RadioGroupHolder } from './Machine.styles'
import { MachineProps } from './Machine.types'
import { theme } from '../../theme/theme'
import { API } from '../../providers/API'

export const Machine: React.FC<MachineProps> = (
  { data, service = false },
) => {
  const [updatedMachineData, setUpdatedMachineData] = useState({
    machineId: data.id, machineStatus: data.status, partList: '', partsPrice: '',
  })
  const [machineStatus, setMachineStatus] = useState<string>(data.status)
  const [approvedForService, setApprovedForService] = useState<boolean>(false)
  const [declinedService, setDeclinedService] = useState<boolean>(false)
  const [returnToServiceSuccess, setReturnToServiceSuccess] = useState<boolean | null>(null)
  const {
    machineServiceData,
    setMachineServiceData,
    machineDeclinedServiceData,
    setMachineDeclinedServiceData,
    userAccess,
    userInfo,
  } = useContext(GlobalStateContext)
  const history = useHistory()

  const updateMachineData = (item: any, itemData: any) => {
    if (item === 'status') { setUpdatedMachineData({ ...updatedMachineData, machineStatus: itemData }) }
    if (item === 'parts') { setUpdatedMachineData({ ...updatedMachineData, partList: itemData }) }
    if (item === 'price') { setUpdatedMachineData({ ...updatedMachineData, partsPrice: itemData }) }
  }

  const debouncedUpdateMachineData = useCallback(
    debounce(updateMachineData, 1000),
    [updatedMachineData],
  )

  const firstUpdate = useRef(true)
  const firstUpdate2 = useRef(true)
  const firstUpdate3 = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if (approvedForService === true) {
      setDeclinedService(false)

      setMachineServiceData(
        {
          machineData: [
            ...machineServiceData.machineData,
            { machineId: data.id },
          ],
        },
      )
    } else {
      // user has unchecked the approval checkbox - removing from list of approved machines
      const filteredMachineServiceData = machineServiceData.machineData.filter(
        (item:any) => item.machineId !== data.id,
      )

      setMachineServiceData(
        {
          machineData: [
            ...filteredMachineServiceData,
          ],
        },
      )
    }
  }, [approvedForService])

  useEffect(() => {
    if (firstUpdate3.current) {
      firstUpdate3.current = false
      return
    }

    if (declinedService === true) {
      setApprovedForService(false)

      setMachineDeclinedServiceData(
        {
          machineData: [
            ...machineDeclinedServiceData.machineData,
            { machineId: data.id },
          ],
        },
      )
    } else {
      // user has unchecked the Deny Service checkbox - removing from list of declined machines
      const filteredMachineDeclinedServiceData = machineDeclinedServiceData.machineData.filter(
        (item:any) => item.machineId !== data.id,
      )

      setMachineServiceData(
        {
          machineData: [
            ...filteredMachineDeclinedServiceData,
          ],
        },
      )
    }
  }, [declinedService])

  useEffect(() => {
    if (firstUpdate2.current) {
      firstUpdate2.current = false
      return
    }

    // remove current edited machine to avoid duplication when adding it bellow
    const filteredMachineServiceData = machineServiceData.machineData.filter(
      (item:any) => item.machineId !== data.id,
    )

    setMachineServiceData(
      {
        machineData: [
          ...filteredMachineServiceData,
          { ...updatedMachineData },
        ],
      },
    )
  }, [updatedMachineData])

  useEffect(() => {
    setMachineStatus(data.status)
  }, [data])

  const returnToService = () => {
    const eventData: object = {
      eventType: 'връщане от брак',
      userId: userInfo.id,
      machineData: [
        {
          machineId: data.id,
          machineStatus: 'за ремонт',
        },
      ],
    }

    API.post('/restoreWork', eventData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
      setReturnToServiceSuccess(true)
    }).catch(() => {
      setReturnToServiceSuccess(false)
    })
  }

  return (
    <MachineHolder waitingForApproval={
      !!(data.partList || data.partsPrice) && service && userAccess < 3
}
    >
      <MachineDataList>
        <li>
          <span>Производител:</span>
          {data.manufacturer}
        </li>
        <li>
          <span>Модел:</span>
          {data.model}
        </li>
        <li>
          <span>S/N:</span>
          {data.serialNumber}
        </li>
        <li>
          <span>B-code:</span>
          {data.barcode}
        </li>
        <li>
          <span>Локация:</span>
          {data.locationType}
        </li>
        <li>
          <span>Статус:</span>
          {returnToServiceSuccess ? 'за ремонт' : data.status}
        </li>
      </MachineDataList>
      {userAccess === 3 && data.status === 'брак' && (
        returnToServiceSuccess === true ? (
          <Alert severity="success" style={{ marginTop: theme.space.md }}>Машината е върната за ремонт успешно</Alert>
        ) : returnToServiceSuccess === false ? (
          <Alert severity="error" onClose={() => setReturnToServiceSuccess(null)} style={{ marginTop: theme.space.md }}>Възникна грешка при записването на данните</Alert>
        ) : (
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={() => returnToService()}
          >
            Въведи в ремонт
          </Button>
        )
      )}
      {userAccess <= 3 ? (
        service ? (
          <>
            <TextField label="Части" name="parts" type="text" value={data.partList} fullWidth multiline onChange={(e) => debouncedUpdateMachineData('parts', e.target.value)} />
            <TextField label="Цена" name="price" type="text" value={data.partsPrice} fullWidth onChange={(e) => debouncedUpdateMachineData('price', e.target.value)} />
          </>
        ) : (
          <>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              size="small"
              fullWidth
              onClick={() => {
                history.push(`/editMachine/${data.id}`)
              }}
              style={{ marginBottom: theme.space.md }}
            >
              Редактирай данни
            </Button>
            {userAccess <= 2 && (
            <RadioGroupHolder>
              <legend>Статус на машината</legend>
              <RadioGroup
                row
                aria-label="status"
                name="status"
                value={machineStatus}
                onChange={
                  (e) => {
                    setMachineStatus(e.currentTarget.value)
                    updateMachineData('status', e.currentTarget.value)
                  }
                }
              >
                <FormControlLabel value="нормален" control={<Radio />} label="Нормален" />
                <FormControlLabel value="профилактика" control={<Radio />} label="Профилактика" />
                <FormControlLabel value="за ремонт" control={<Radio />} label="За ремонт" />
                <FormControlLabel value="одобрен сервиз" control={<Radio />} label="Одобрен сервиз" disabled />
                <FormControlLabel value="брак" control={<Radio />} label="Бракувана" disabled />
              </RadioGroup>
            </RadioGroupHolder>
            )}
          </>
        )
      ) : (
        service && (
        <>
          <div>
            Части:
            {' '}
            {data.partList}
          </div>
          <div>
            Цена:
            {' '}
            {data.partsPrice}
          </div>
          <FormControlLabel
            label="Одобрен ремонт"
            control={(
              <Checkbox
                checked={approvedForService}
                onChange={() => { setApprovedForService(!approvedForService) }}
              />
            )}
          />
          <FormControlLabel
            label="Бракуване"
            control={(
              <Checkbox
                checked={declinedService}
                onChange={() => { setDeclinedService(!declinedService) }}
              />
            )}
          />
        </>
        )
      )}
    </MachineHolder>
  )
}
