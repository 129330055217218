import React, { useContext, useEffect, useState } from 'react'
import * as ls from 'local-storage'
import Pagination from '@mui/material/Pagination'
import Alert from '@mui/material/Alert'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { API } from '../providers/API'
import { Machine } from '../components/Machine/Machine'
import { MachineData } from '../components/Machine/Machine.types'
import { MachineFilters } from '../components/MachineFilters/MachineFilters'
import { ApprovalBox } from '../components/ApprovalBox/ApprovalBox'
import { Loading } from '../components/Loading/Loading'
import { FixedFooterContainer } from '../App.styles'

export const Machines = () => {
  const URLQuery = window.location.search
  const selectedPage = new URLSearchParams(URLQuery).get('selectedPage')
  const [machines, setMachines] = useState([])
  const [paginatedMachines, setPaginatedMachines] = useState([])
  const [page, setPage] = useState(selectedPage ? parseInt(selectedPage) : 1)
  const [selectedBranch, setSelectedBranch] = useState<number | null>(null)
  const {
    userAccess, setPageTitle, isLoading, setIsLoading,
  } = useContext(GlobalStateContext)
  const itemsPerPage = 20

  setPageTitle('Машини')

  useEffect(() => {
    setIsLoading(true)
    if (userAccess <= 2) {
      API.get('/machines', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
        setMachines(response.data)
        setIsLoading(false)
      })
    } else if (userAccess === 3) {
      // replace number with dynamic value from userInfo.bankId
      API.get('/storage/listAllBankMachines/1', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
        setMachines(response.data)
        setIsLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    if (page === 1) {
      setPaginatedMachines(machines.filter((item: any, index: number) => index + 1 <= itemsPerPage))
    } else {
      setPaginatedMachines(machines.filter(
        // eslint-disable-next-line max-len
        (item: any, index: number) => (index + 1 > page * itemsPerPage && index + 1 <= (page + 1) * itemsPerPage),
      ))
    }

    const searchParams = new URLSearchParams(URLQuery)
    searchParams.set('selectedPage', page.toString())
    window.history.replaceState('', '', `?${searchParams.toString()}`)
  }, [machines, page])

  return (
    <FixedFooterContainer>
      <MachineFilters
        setMachinesFunction={setMachines}
        filteredMachines={machines}
        setSelectedBranchFn={setSelectedBranch}
        selectedBranch={selectedBranch}
      />
      {isLoading === true ? (
        <Loading />
      ) : (
        <>
          {paginatedMachines.length > 0 ? (
            paginatedMachines.map((item: MachineData) => (
              <Machine key={item.id} data={item} />
            ))
          ) : (
            <Alert severity="info">Няма намерени машини</Alert>
          )}
          {machines.length > itemsPerPage && (
            <Pagination
              page={page}
              siblingCount={1}
              count={Math.floor(machines.length / itemsPerPage)}
              color="primary"
              onChange={(event, value) => setPage(value)}
            />
          )}
        </>
      )}
      {userAccess <= 2 && (
      <ApprovalBox />
      )}
    </FixedFooterContainer>
  )
}
