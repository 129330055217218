import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as ls from 'local-storage'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { API } from '../providers/API'
import {
  MachineData, branchProps, bankProps, storageProps,
} from '../components/Machine/Machine.types'
import { SuccessNotification } from '../components/SuccessNotification/SuccessNotification.styles'

const ValidationSchema = Yup.object().shape({
  manufacturer: Yup.string().required('Въведете производител'),
  model: Yup.string().required('Въведете модел'),
  type: Yup.string().required('Въведете тип машина'),
  status: Yup.string().required('Въведете статус'),
  serialNumber: Yup.string().required('Въведете сериен номер'),
  barcode: Yup.string().required('Въведете баркод'),
  locationType: Yup.string().required('Въведете тип локация'),
  storage: Yup.string().when('locationType', {
    is: 'склад',
    then: Yup.string().required('Въведете склад'),
  }),
  bank: Yup.string().when('locationType', {
    is: 'банка',
    then: Yup.string().required('Въведете банка'),
  }),
  locationId: Yup.string().when('locationType', {
    is: 'банка',
    then: Yup.string().required('Въведете клон'),
  }),
})

interface ParamProps {
  machineId: string
}

export const CreateMachine = () => {
  const { setPageTitle, machineTypes, userAccess } = useContext(GlobalStateContext)

  const { machineId } = useParams<ParamProps>()
  setPageTitle(machineId ? 'Промяна на машина' : 'Нова Машина')

  const [branchListState, setBranchListState] = useState<branchProps[]>([])
  const [branchListStatePerm, setBranchListStatePerm] = useState<branchProps[]>([])
  const [bankListState, setBankListState] = useState<bankProps[]>([])
  const [storageListState, setStorageListState] = useState<storageProps[]>([])
  const [machineData, setMachineData] = useState<MachineData>()
  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  useEffect(() => {
    API.get('/bank', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const banksList:any = []
      response.data.forEach((item:any) => {
        banksList.push({ label: item.bankName })
      })
      setBankListState(banksList)
    })

    API.get('/branch', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const branchList:any = []
      response.data.forEach((item:any) => {
        branchList.push({ label: item.branchName, bankName: item.bankName, value: item.id })
      })

      setBranchListState(branchList)
      setBranchListStatePerm(branchList)
    })

    API.get('/storage', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const storageList:any = []
      response.data.forEach((item:any) => {
        storageList.push({ label: item.location, id: item.id })
      })
      setStorageListState(storageList)
    })
  }, [])

  if (machineId) {
    useEffect(() => {
      API.get(`/machines/${machineId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
        // eslint-disable-next-line
        const storageSelected = storageListState.filter((item) => item.id === response.data.locationId)
        setMachineData({ ...response.data, storage: storageSelected[0] })
      })
    }, [storageListState])
  }

  const updateBranchList = (value: any) => {
    const newArray = branchListStatePerm.filter((item:any) => item.bankName === value.label)
    setBranchListState(newArray)
  }

  return (
    <Formik
      initialValues={{
        manufacturer: machineData ? machineData.manufacturer : '',
        model: machineData ? machineData.model : '',
        type: machineData ? machineData.type : '',
        status: machineData ? machineData.status : '',
        serialNumber: machineData ? machineData.serialNumber : '',
        barcode: machineData ? machineData.barcode : '',
        locationType: machineData ? machineData.locationType : '',
        storage: '',
        bank: machineData ? 'asasdasd' : '',
        locationId: machineData ? machineData.locationId : '',

      }}
      validationSchema={ValidationSchema}
      enableReinitialize
      onSubmit={(values, actions) => {
        if (machineId) {
          API.put(`/machines/${machineId}`, values, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
            setShowSuccess(true)
            setTimeout(() => setShowSuccess(false), 3000)
          })
        } else {
          API.post('/machines', values, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
            actions.resetForm()
            setShowSuccess(true)
            setTimeout(() => setShowSuccess(false), 3000)
          })
        }
      }}
    >
      {({
        handleSubmit, setFieldValue, values, errors,
      }) => (
        <form onSubmit={handleSubmit}>
          {userAccess <= 2 && (
            <>
              <Autocomplete
                disablePortal
                value={values.manufacturer}
                options={['Hyundai MIB', 'Laurel']}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Производител"
                    name="manufacturer"
                    error={!!errors.manufacturer}
                    helperText={!!errors.manufacturer && 'Въведете производител'}
                  />
                )}
                onChange={(event, newValue) => {
                  setFieldValue('manufacturer', newValue)
                }}
              />
              <Autocomplete
                disablePortal
                value={values.model}
                options={['SB800', 'X7']}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Модел"
                    name="model"
                    error={!!errors.model}
                    helperText={!!errors.model && 'Въведете модел'}
                  />
                )}
                onChange={(event, newValue) => {
                  setFieldValue('model', newValue)
                }}
              />
              <Autocomplete
                disablePortal
                value={values.type}
                options={machineTypes}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Тип машина"
                    name="type"
                    error={!!errors.type}
                    helperText={!!errors.type && 'Въведете тип машина'}
                  />
                )}
                onChange={(event, newValue) => {
                  setFieldValue('type', newValue)
                }}
              />
              <Autocomplete
                disablePortal
                value={values.status}
                options={['нормален', 'профилактика', 'за ремонт', 'в сервиз']}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Статус"
                    name="status"
                    error={!!errors.status}
                    helperText={!!errors.status && 'Въведете статус'}
                  />
                )}
                onChange={(event, newValue) => {
                  setFieldValue('status', newValue)
                }}
              />
              <TextField
                label="Сериен номер"
                name="serialNumber"
                value={values.serialNumber}
                error={!!errors.serialNumber}
                helperText={errors.serialNumber}
                onChange={(e) => {
                  setFieldValue('serialNumber', e.target.value)
                }}
              />
              <TextField
                label="Баркод"
                name="barcode"
                value={values.barcode}
                error={!!errors.barcode}
                helperText={errors.barcode}
                onChange={(e) => {
                  setFieldValue('barcode', e.target.value)
                }}
              />

              <hr />
              <br />
            </>
          )}

          <Autocomplete
            disablePortal
            value={values.locationType}
            options={['склад', 'банка']}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Вид локация"
                name="locationType"
                error={!!errors.locationType}
                helperText={errors.locationType}
              />
            )}
            onChange={(event, newValue) => {
              setFieldValue('locationType', newValue)
            }}
          />

          {values.locationType === 'склад' && (
            <Autocomplete
              disablePortal
              // @ts-ignore-next-line
              value={values.storage}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={storageListState}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Име на склад"
                  name="storage"
                  error={!!errors.storage}
                  helperText={errors.storage}
                />
              )}
              onChange={(event, newValue) => {
                setFieldValue('storage', newValue)
                setFieldValue('locationId', newValue!.id)
              }}
            />
          )}

          {values.locationType === 'банка' && (
            <>
              <Autocomplete
                disablePortal
                options={bankListState}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Име на банка"
                    name="bank"
                    error={!!errors.bank}
                    helperText={errors.bank}
                  />
                )}
                onChange={(event, newValue) => {
                  updateBranchList(newValue)
                  setFieldValue('bank', newValue)
                }}
              />

              <Autocomplete
                disablePortal
                options={branchListState}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Име на клон"
                    name="locationId"
                    error={!!errors.locationId}
                    helperText={errors.locationId}
                  />
                )}
                onChange={(event, newValue) => {
                  setFieldValue('locationId', newValue!.value)
                }}
              />
            </>
          )}

          <Button
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
          >
            {machineId ? 'Промени машина' : 'Регистрирай машина'}
          </Button>
          {showSuccess && <SuccessNotification>Данните са записани успешно!</SuccessNotification>}
          {machineId && (
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            fullWidth
            onClick={() => { window.history.back() }}
            style={{ marginTop: '10px' }}
          >
            Назад
          </Button>
          )}

        </form>
      )}
    </Formik>
  )
}
