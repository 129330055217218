import React, {
  Dispatch, createContext, useState, SetStateAction,
} from 'react'

import * as ls from 'local-storage'

interface UserInfoProps {
  name?: string
  access?: number
  bankId?: number | undefined
  branchId?: number
  email?: string
  id?: number
}

interface MachineData {
  status: string
  parts: string
  partsPrice: string
}

interface MachineServiceDataProps {
  machineData: MachineData[]
}

interface GlobalContextProps {
  slideoutOpen: boolean,
  setSlideoutOpen: Dispatch<SetStateAction<boolean>>,
  approvalBoxOpen: boolean,
  setApprovalBoxOpen: Dispatch<SetStateAction<boolean>>,
  pageTitle: string
  setPageTitle: Dispatch<SetStateAction<string>>,
  userAccess: number,
  setUserAccess: Dispatch<SetStateAction<number>>,
  userInfo: UserInfoProps
  setUserInfo: Dispatch<SetStateAction<UserInfoProps>>,
  permissionsList: {},
  machineTypes: string[],
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  machineServiceData: MachineServiceDataProps,
  setMachineServiceData: Dispatch<SetStateAction<any>>,
  machineDeclinedServiceData: MachineServiceDataProps,
  setMachineDeclinedServiceData: Dispatch<SetStateAction<any>>,
}

export const GlobalStateContext = createContext<GlobalContextProps>({
  slideoutOpen: false,
  setSlideoutOpen: () => {},
  approvalBoxOpen: false,
  setApprovalBoxOpen: () => {},
  pageTitle: 'Начало',
  setPageTitle: () => {},
  userAccess: 0,
  setUserAccess: () => {},
  userInfo: { name: '' },
  setUserInfo: () => {},
  permissionsList: {},
  machineTypes: [],
  isLoading: false,
  setIsLoading: () => {},
  machineServiceData: { machineData: [] },
  setMachineServiceData: () => {},
  machineDeclinedServiceData: { machineData: [] },
  setMachineDeclinedServiceData: () => {},
})

const permissionsList = {
  0: 'search',
  1: 'service',
  2: 'archive',
  3: 'storage',
}

const machineTypes = [
  'банкнотоброячна',
  'банкнотосортираща',
  'монетоброячна',
  'монетосортираща',
  'бандеролираща']

export const GlobalStateProvider: React.FC = ({ children }) => {
  const [slideoutOpen, setSlideoutOpen] = useState<boolean>(false)
  const [approvalBoxOpen, setApprovalBoxOpen] = useState<boolean>(false)
  const [pageTitle, setPageTitle] = useState('')
  const initialUserAccess: number = ls.get('userAccess') !== null ? ls.get('userAccess') : 0
  const [userAccess, setUserAccess] = useState<number>(initialUserAccess)
  const [userInfo, setUserInfo] = useState(ls.get('userInfo') !== null ? ls.get('userInfo') : {})
  const [isLoading, setIsLoading] = useState(false)
  const [machineServiceData, setMachineServiceData] = useState({ machineData: [] })
  const [machineDeclinedServiceData, setMachineDeclinedServiceData] = useState({ machineData: [] })

  return (
    <GlobalStateContext.Provider value={{
      slideoutOpen,
      setSlideoutOpen,
      approvalBoxOpen,
      setApprovalBoxOpen,
      pageTitle,
      setPageTitle,
      userAccess,
      setUserAccess,
      userInfo,
      setUserInfo,
      permissionsList,
      machineTypes,
      isLoading,
      setIsLoading,
      machineServiceData,
      setMachineServiceData,
      machineDeclinedServiceData,
      setMachineDeclinedServiceData,
    }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}
